export const getToke = async () => {
    let token
    window.location.search.substr(1).split('&').forEach((parm) => {
        if (parm.indexOf('token=') > -1) {
            token = parm.substr('token='.length, parm.length)
        }
    })
    if (!token) {
        await goLoginPage()
        return undefined
    }
    /*
    console.log(window.location.search.substr(1))
    const store = useOAuthStore()
    const {token} = storeToRefs(store)
    console.log(token.value)
    if (!token.value) {
        let data = await fetch('/login')
        let url = await data.text()
        window.location.href = `${url}` + "?url=" + window.location.origin
    }
    return token.value
    */
    return token
}

export const goLoginPage = async () => {
    const data = await fetch(`/login?url=${window.location.origin}`)
    window.location.replace(data.url)
}

export const goResetPasswordPage = async () => {
    const token = await getToke()
    const data = await fetch(`/resetpassword?url=${window.location.origin}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        window.location.replace(data.url)
    } else if (data.status === 401) {
        await goLoginPage()
    }
}

export const getServerTime = async () => {
    const token = await getToke()
    const data = await fetch('/api/servertime', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.text()
    } else {
        return undefined
    }
}

export const getConfig = async () => {
    const data = await fetch('/api/config')
    if (data.ok) {
        return await data.json()
    } else {
        return {}
    }
}

/**
 * 設備綁定組織
 *
 * @param deviceId 設備ID
 * @param organizationAppId 組織AppId（tag用）
 * */
export const deviceBindOrganization = async (deviceId, organizationAppId) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(id => body.append('deviceId', id))
    } else {
        body.append('deviceId', deviceId)
    }
    body.append('organizationAppId', organizationAppId)
    const data = await fetch('/api/manage/device/bind/organization', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return {
        ok: data.ok,
        message: await data.text()
    }
}


/**
 * 設備解綁定組織
 *
 * @param deviceId 設備ID
 * */
export const deviceUnbindOrganization = async (deviceId) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(id => body.append('deviceId', id))
    } else {
        body.append('deviceId', deviceId)
    }
    const data = await fetch(`/api/manage/device/unbind/organization`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return {
        ok: data.ok,
        message: await data.text()
    }
}

/**
 * 搜尋未綁定組織之設備
 *
 * @param deviceId 設備ID，模糊搜尋用
 * @param enabled 設備是否啟用
 * @param page 頁數
 * */
export const getDevices = async ({
                                     deviceId,
                                     tags,
                                     excludeTags,
                                     enabled,
                                     unbinding,
                                     organizationAppId,
                                     districtAppId,
                                     page
                                 }) => {
    const token = await getToke()
    let url = '/api/oauth/device?'
    if (deviceId) {
        if (deviceId.includes(',')) {
            url += `deviceId=${deviceId}&`
        } else {
            url += `idLike=${deviceId}&`
        }
    }
    if (tags) {
        if (Array.isArray(tags)) {
            url += `tags=`
            tags.forEach(it => {
                url += `${it},`
            })
            url = url.substring(0, url.length - 2)
            url += `&`
        } else {
            url += `tags=${tags}&`
        }
    }
    if (excludeTags) {
        if (Array.isArray(excludeTags)) {
            url += `excludeTags=`
            excludeTags.forEach(it => {
                url += `${it},`
            })
            url = url.substring(0, url.length - 2)
            url += `&`
        } else {
            url += `excludeTags=${excludeTags}&`
        }
    }
    if (!(enabled === undefined || enabled === null)) url += `enabled=${enabled}&`
    if (!(unbinding === undefined || unbinding === null)) url += `unbinding=${unbinding}&`
    if (organizationAppId) url += `organizationAppId=${organizationAppId}&`
    if (districtAppId) url += `districtAppId=${districtAppId}&`
    url += `page=${page}&`
    const data = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}

/**
 * 創建組織
 *
 * @param name 組織名稱
 * @param appId 組織AppId（tag用）
 * */
export const createOrganization = async ({name, appId}) => {
    const body = new URLSearchParams()
    body.append('name', name)
    body.append('appId', appId)
    const token = await getToke()
    const data = await fetch(`/api/admin/organization/create`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}

/**
 * 搜尋使用者所屬組織
 * */
export const getOrganizations = async () => {
    const token = await getToke()
    const data = await fetch(`/api/oauth/organization`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}

/**
 * 管理者搜尋所有組織
 * */
export const getAllOrganizations = async () => {
    const token = await getToke()
    const data = await fetch(`/api/manage/organization`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}

/**
 * 管理者刪除組織
 *
 * @param organizationId 組織ID
 * */
export const deleteOrganization = async (organizationId) => {
    const token = await getToke()
    const data = await fetch(`/api/admin/organization/${organizationId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}

/**
 * 搜尋車格設備
 *
 * @param organizationAppId 組織AppId
 * @param fieldName 場域名稱
 * @param excludeFieldName 排除場域名稱
 * @param page 頁數
 * */
export const getPositions = async (organizationAppId, districtAppId, excludeDistrictAppId, page) => {
    const token = await getToke()
    let url = `/api/oauth/position?`
    if (organizationAppId) url += `organizationAppId=${organizationAppId}&`
    if (districtAppId) url += `fieldName=${districtAppId}&`
    if (excludeDistrictAppId) url += `excludeDistrictAppId=${excludeDistrictAppId}&`
    url += `page=${page}`
    const data = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}

/**
 * 根據位置取得停車格清單
 *
 * @param organizationAppId 組織AppId
 * @param fieldName 場域名稱
 * @param lat 精度
 * @param lng 緯度
 * @param distance 距離(m)
 * */
export const getPositionsNearBy = async (organizationAppId, fieldName, lat, lng, distance) => {
    const token = await getToke()
    let url = `/api/oauth/position/nearBy?organizationAppId=${organizationAppId}&fieldName=${fieldName}&lat=${lat}&lng=${lng}&distance=${distance}`
    const data = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}

/**
 * 根據關鍵字取得停車格清單
 *
 * @param keyword 關鍵字
 * */
export const getPositionsKeyword = async (keyword) => {
    const token = await getToke()
    let url = `/api/oauth/position/keyword?keyword=${keyword}`
    const data = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}

/**
 * 取得停車格資料
 *
 * @param positionId 停車格ID
 * */
export const getPosition = async (positionId) => {
    const token = await getToke()
    const data = await fetch(`/api/oauth/position/${positionId}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return undefined
}

/**
 * 取得設備資料
 *
 * @param deviceId 設備ID
 * */
export const getDevice = async (deviceId) => {
    const token = await getToke()
    const data = await fetch(`/api/oauth/device/${deviceId}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return undefined
}

/**
 * 取得設備網路數據
 *
 * @param deviceId 設備ID
 * */
export const getDeviceNet = async (deviceId) => {
    const token = await getToke()
    const data = await fetch(`/api/oauth/device/net/${deviceId}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return undefined
}

/**
 * 創建或更新停車格
 * @param id 停車格ID
 * @param name 車格名稱
 * @param nameCode 車格代碼
 * @param address 地址
 * @param addressCode 地址代碼
 * @param lat 緯度
 * @param lng 經度
 * @param isDelete 是否移除
 * @param deviceId 地磁Id
 * @param organizationAppId 組織AppId
 * @param districtAppId 場域名稱
 * */
export const createOrUpdatePosition = async ({
                                                 id,
                                                 name,
                                                 nameCode,
                                                 address,
                                                 addressCode,
                                                 lat,
                                                 lng,
                                                 isDelete,
                                                 isHighSensitivity,
                                                 deviceId,
                                                 organizationAppId,
                                                 districtAppId
                                             }) => {
    const body = new URLSearchParams()
    if (id) body.append('id', id)
    if (name) body.append('name', name)
    if (nameCode) body.append('nameCode', nameCode)
    if (address) body.append('address', address)
    if (addressCode) body.append('addressCode', addressCode)
    if (lat) body.append('lat', lat)
    if (lng) body.append('lng', lng)
    body.append('isDelete', isDelete)
    body.append("isHighSensitivity", isHighSensitivity)
    if (deviceId) body.append('deviceId', deviceId)
    if (organizationAppId) body.append('organizationAppId', organizationAppId)
    if (districtAppId) body.append('districtAppId', districtAppId)
    const token = await getToke()
    const data = await fetch(`/api/oauth/position`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: body
    })
    if (data.ok) {
        return {
            ok: data.ok,
            message: "",
            data: await data.json()
        }
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return {
        ok: false,
        message: await data.text()
    }
}

/**
 * 更新停車格
 *
 * @param positionId 停車格ID
 * @param params 更新內容
 * */
export const patchPosition = async (positionId, params) => {
    const body = new URLSearchParams()
    for (let key in params) {
        body.append(key, params[key])
    }
    const token = await getToke()
    const data = await fetch(`/api/oauth/position/${positionId}`, {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: body
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return undefined
}

/**
 * 停車格解綁設備
 *
 * @param positionId 停車格ID
 * */
export const unbindPosition = async (positionId) => {
    const body = new URLSearchParams()
    const token = await getToke()
    const data = await fetch(`/api/oauth/position/unbind/${positionId}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: body
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return undefined
}

/**
 * 使用者個人資料
 * */
export const me = async () => {
    const token = await getToke()
    const data = await fetch(`/api/oauth/me`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return undefined
}

/**
 * 管理者搜尋所有使用者
 * */
export const getUsers = async () => {
    const token = await getToke()
    const data = await fetch(`/api/admin/user`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}

/**
 * 管理者搜尋單一使用者
 * */
export const getUser = async (username) => {
    const token = await getToke()
    const data = await fetch(`/api/admin/user/${username}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return undefined
}

/**
 * 管理者搜尋綁定組織之使用者ID清單
 *
 * @param organizationId 組織ID
 * */
export const getUserIdsByOrganizationId = async (organizationId) => {
    const token = await getToke()
    const data = await fetch(`/api/admin/user/id/${organizationId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}


/**
 * 管理者新增使用者
 *
 * @param userId 使用者ID
 * @param password 密碼
 * @param role 角色，ROOT，USER，MANAGER，MEMBER，LEADER，STAFF
 * @param enable 是否啟用
 * */
export const createUser = async (username, password) => {
    const token = await getToke()
    const body = new URLSearchParams()
    body.append('username', username)
    body.append('password', password)
    const data = await fetch(`/api/admin/user/create`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}

/**
 * 管理者修改使用者
 *
 * @param userId 使用者ID
 * @param password 密碼
 * @param role 角色，ROOT，USER，MANAGER，MEMBER，LEADER，STAFF
 * @param enable 是否啟用
 * @param firstPermissions 第一權限
 * @param vehicleDetectorSetting 車檢器設定權限
 * */
export const adminPatchUser = async ({userId, password, role, enable, firstPermissions, vehicleDetectorSetting}) => {
    const token = await getToke()
    const body = new URLSearchParams()
    body.append('userId', userId)
    if (password) body.append('password', password)
    if (role) body.append('role', role)
    if (enable !== undefined && enable !== null) body.append('enable', enable)
    if (firstPermissions) body.append('firstPermissions', firstPermissions)
    if (vehicleDetectorSetting) body.append('vehicleDetectorSetting', vehicleDetectorSetting)
    const data = await fetch(`/api/admin/user`, {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}

/**
 * 使用者修改自己的資料
 */
export const patchUserBySelf = async ({username, password}) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (username) body.append('username', username)
    if (password) body.append('password', password)
    const data = await fetch(`/api/oauth/user`, {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}

/**
 * 使用者綁定組織
 *
 * @param userId 使用者ID
 * @param organizationId 組織ID
 * */
export const userBindOrganization = async (userId, organizationId) => {
    const token = await getToke()
    const body = new URLSearchParams()
    body.append('userId', userId)
    body.append('organizationId', organizationId)
    const data = await fetch('/api/admin/user/bind/organization', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}

/**
 * 使用者解綁定組織
 *
 * @param userId 使用者ID
 * @param organizationId 組織ID
 * */
export const userUnbindOrganization = async (userId, organizationId) => {
    const token = await getToke()
    const body = new URLSearchParams()
    body.append('userId', userId)
    body.append('organizationId', organizationId)
    const data = await fetch(`/api/admin/user/unbind/organization`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}

/**
 * 搜尋設備設定
 *
 * @param deviceId 設備ID
 * */
export const getDeviceSetting = async (deviceId) => {
    const token = await getToke()
    const data = await fetch(`/api/manage/device/setting/${deviceId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return undefined
}

/**
 * 更新設備設定
 *
 * @param deviceId 設備ID
 * @param params 設定內容
 * */
export const patchDeviceSetting = async (deviceId, params) => {
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(id => {
            body.append('deviceId', id)
        })
    } else {
        body.append('deviceId', deviceId)
    }
    for (let key in params) {
        body.append(key, params[key])
    }
    const token = await getToke()
    const data = await fetch(`/api/manage/device/setting`, {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}
/**
 * 重設預設值
 *
 * @param deviceId 設備ID
 * */
export const setDefaultDeviceSetting = async (deviceId) => {
    const body = new URLSearchParams()

    const token = await getToke()
    const data = await fetch(`/api/manage/device/setting/default/${deviceId}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data
}

/**
 * 搜尋設備上傳資料紀錄
 *
 * @param deviceId 設備ID
 * @param startTime 搜尋開始時間
 * @param endTime 搜尋結束時間
 * @param page 頁數
 * */
export const getStatusLogs = async (deviceId, startTime, endTime, page) => {
    const token = await getToke()
    let url = `/api/oauth/device/status/list/${deviceId}?`
    if (startTime) url += `startTime=${startTime}&`
    if (endTime) url += `endTime=${endTime}&`
    if (page) url += `page=${page}`
    const data = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
        return []
    }
    return []
}

/**
 * 搜尋設備車位狀態資料紀錄
 *
 * @param deviceId 設備ID
 * @param startTime 搜尋開始時間
 * @param endTime 搜尋結束時間
 * @param page 頁數
 * */
export const getChangeLogs = async (deviceId, startTime, endTime, page) => {
    const token = await getToke()
    const data = await fetch(`/api/oauth/device/changeLog/list/${deviceId}?page=${page}&startTime=${startTime}&endTime=${endTime}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
        return []
    }
    return []
}

/**
 * 搜尋設備異常狀態資料紀錄
 *
 * @param deviceId 設備ID
 * @param startTime 搜尋開始時間
 * @param endTime 搜尋結束時間
 * @param page 頁數
 * */
export const getUnmoralLogs = async (deviceId, startTime, endTime, page) => {
    const token = await getToke()
    const data = await fetch(`/api/oauth/device/unmoralLog/list/${deviceId}?page=${page}&startTime=${startTime}&endTime=${endTime}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
        return []
    }
    return []
}

/**
 * 搜尋組織底下場域清單
 *
 * @param organizationAppId 組織AppId
 * */
export const getAllField = async (organizationAppId) => {
    const token = await getToke()
    const data = await fetch(`/api/admin/field?organizationAppId=${organizationAppId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
        return []
    }
    return []
}

/**
 * 新增場域
 *
 * @param organizationAppId 組織AppId
 * @param name 場域名稱
 * */
export const createField = async ({organizationAppId, name}) => {
    const body = new URLSearchParams()
    body.append('name', name)
    const token = await getToke()
    const data = await fetch(`/api/admin/field/create/${organizationAppId}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}

/**
 * 刪除場域
 *
 * @param fieldId 場域ID
 * */
export const deleteField = async (fieldId) => {
    const token = await getToke()
    const data = await fetch(`/api/admin/field/${fieldId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}

/**
 * 設備綁定場域
 *
 * @param deviceId 設備ID
 * @param fieldId 場域ID
 * */
export const deviceBindField = async (deviceId, fieldId) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(id => body.append('deviceId', id))
    } else {
        body.append('deviceId', deviceId)
    }
    body.append('fieldId', fieldId)
    const data = await fetch(`/api/manage/device/bind/field`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return {
        ok: data.ok,
        message: await data.text()
    }
}

/**
 * 設備解除綁定場域
 *
 * @param deviceId 設備ID
 * @param fieldId 場域ID
 * */
export const deviceUnbindField = async (deviceId) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(id => body.append('deviceId', id))
    } else {
        body.append('deviceId', deviceId)
    }
    const data = await fetch(`/api/manage/device/unbind/field`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return {
        ok: data.ok,
        message: await data.text()
    }
}

/**
 * 取得韌體
 * */
export const getFirmwares = async (bid) => {
    const token = await getToke()
    let url = `/api/manage/firmware?`
    if (bid) {
        if (Array.isArray(bid)) {
            bid.forEach(bid => url += `bid=${bid}&`)
        } else {
            url += `bid=${bid}&`
        }
    }
    const data = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        },
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
        return []
    }
    return []
}

/**
 * 上傳韌體
 *
 * @param name 韌體名稱
 * @param bid 適用批號
 * @param version 韌體版本
 * @param description 韌體說明
 * @param file 韌體檔案
 * */
export const uploadFirmware = async (name, bid, version, description, file) => {
    const token = await getToke()
    const body = new FormData()
    body.append('name', name)
    body.append('bid', bid)
    body.append('version', version)
    body.append('description', description)
    body.append('file', file)
    const data = await fetch(`/api/manage/firmware`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return undefined
}

/**
 * 刪除韌體
 * */
export const deleteFirmware = async (firmwareId) => {
    const token = await getToke()
    const data = await fetch(`/api/manage/firmware/${firmwareId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}

/**
 * 升級設備
 * */
export const upgradeDevice = async (deviceId, firmwareId) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(id => body.append('deviceId', id))
    } else {
        body.append('deviceId', deviceId)
    }
    body.append('firmwareId', firmwareId)
    const data = await fetch(`/api/manage/device/upgrade`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return {
        ok: data.ok,
        message: await data.text()
    }
}

/**
 * 升級設備
 * */
export const cancelUpgradeDevice = async (deviceId) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(id => body.append('deviceId', id))
    } else {
        body.append('deviceId', deviceId)
    }
    const data = await fetch(`/api/manage/device/upgrade/cancel`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return {
        ok: data.ok,
        message: await data.text()
    }
}

/**
 * 管理者搜尋所有組織
 * */
export const getAllTags = async () => {
    const token = await getToke()
    const data = await fetch(`/api/manage/device/tag`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}

/**
 * 管理者搜尋所有組織
 * */
export const updateTags = async (deviceId, add, remove) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(s => body.append('deviceId', s))
    } else if (deviceId) {
        body.append('deviceId', deviceId)
    }
    if (Array.isArray(add)) {
        add.forEach(s => body.append('add', s))
    } else if (add) {
        body.append('add', add)
    }
    if (Array.isArray(remove)) {
        remove.forEach(s => body.append('remove', s))
    } else if (remove) {
        body.append('remove', add)
    }
    const data = await fetch(`/api/manage/device/tag`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}

export const getPositionCount = async (organizationId, fieldId, day) => {
    const token = await getToke()
    const body = new URLSearchParams()
    let url = '/api/oauth/position/count'
    if (organizationId) {
        url += `/${organizationId}`
    }
    if (fieldId) {
        url += `/${fieldId}`
    }
    if (day) {
        url += `?day=${day}`
    }
    const data = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}

/**
 * 查詢操作紀錄
 * */
export const getActionLogs = async (keyword) => {
    const token = await getToke()
    const body = new URLSearchParams()
    body.append('keyword', keyword)
    const data = await fetch('/api/manage/log/keyword', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}

