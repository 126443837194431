<template>
  <v-container>
    <v-row>
      <v-col class="col-sm-9 col-md-10">
        <v-expansion-panels accordion flat readonly v-model="expansion" multiple>
          <v-expansion-panel key="0">
            <v-expansion-panel-header class="p-0 align-items-baseline">
              <v-text-field class="col-12"
                            v-model="keyword"
                            placeholder="車格編號、地址、地磁ID"
                            dense/>
              <v-btn class="" icon @click="tagging">
                <v-icon>arrow_drop_down</v-icon>
              </v-btn>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field v-model="excludeKeyword"
                            placeholder="車格編號、地址、地磁ID"
                            label="排除關鍵字"
                            dense/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col class="col-sm-3 col-md-2">
        <v-btn-toggle dense>
          <v-btn color="primary" @click="reloadDevices" :disabled="loading" dark>重新整理</v-btn>
          <v-btn color="green"
                 v-show="selectDistrictAppId"
                 v-if="['ROOT', 'MANAGER','STAFF'].includes(userRole)"
                 @click="selectParkingPositionUpdate = {organizationAppId:selectOrganizationAppId,districtAppId:selectDistrictAppId}"
                 dark>新增車格
          </v-btn>
          <v-menu bottom offset-y v-if="['ROOT', 'MANAGER','STAFF'].includes(userRole)" :disabled="loading">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="orange" v-bind="attrs" v-on="on" dark>批量</v-btn>
            </template>
            <v-list>
              <v-list-item
                  v-if="vehicleDetectorEnable"
                  v-show="(firstPermissions & 0b000000000000000000000010000000) === 0b000000000000000000000010000000"
                  @click="selectDeviceSetting=devices.filter(device => device.selected && device.deviceId).map(device => device.deviceId)">
                <v-list-item-content>
                  <v-list-item-title class="text-primary">批量設定</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                  v-if="vehicleDetectorEnable"
                  v-show="(firstPermissions & 0b000000000000000000000100000000) === 0b000000000000000000000100000000"
                  @click="selectDeviceUpgrade=devices.filter(device => device.selected && device.deviceId).map(device => {
                    return {deviceId:device.deviceId, firmwareVersion:device.firmwareVersion, bid:device.bid ?? 17}
                  })">
                <v-list-item-content>
                  <v-list-item-title class="cyan--text">批量升級</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-menu bottom offset-x left>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on"
                               v-if="organizationEnable"
                               v-show="(firstPermissions & 0b000000000000000000100000000000) === 0b000000000000000000100000000000">
                    <v-list-item-content>
                      <v-list-item-title class="orange--text">批量編輯組織、場域</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list dense>
                  <template v-for="organization in organizations">
                    <span :key="organization.id">
                      <v-menu bottom offset-x left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item v-bind="attrs" v-on="on">
                            <v-list-item-content>
                              <v-list-item-title>{{ organization.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-list dense>
                          <v-list-item @click="selectedDeviceChangeOrganization(organization)">
                            <v-list-item-content>
                              <v-list-item-title>無場域</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <template v-for="district in organization.districts">
                            <span :key="district.id">
                              <v-list-item @click="selectedDeviceChangeDistrict(district)">
                                <v-list-item-content>
                                  <v-list-item-title>{{ district.name }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider class="m-0"/>
                            </span>
                          </template>
                        </v-list>
                      </v-menu>
                      <v-divider class="m-0"/>
                    </span>
                  </template>
                </v-list>
              </v-menu>
              <v-list-item
                  v-show="(firstPermissions & 0b000000000000000000001000000000) === 0b000000000000000000001000000000"
                  @click="selectDeviceAddTag=devices.filter(device => device.selected && device.deviceId).map(device => device.deviceId)">
                <v-list-item-content>
                  <v-list-item-title class="deep-orange--text">批量新增標籤</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                  v-show="(firstPermissions & 0b000000000000000000001000000000) === 0b000000000000000000001000000000"
                  @click="selectDeviceRemoveTag=devices.filter(device => device.selected && device.deviceId).map(device => device.deviceId)">
                <v-list-item-content>
                  <v-list-item-title class="green--text">批量移除標籤</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-progress-linear class="my-4" color="main" v-if="loading" indeterminate/>
    <v-divider v-else/>
    <v-row>
      <v-col>
        <v-btn icon v-show="showBindingDevicePosition===false" @click="showBindingDevicePosition=true">
          <v-icon>check_box_outline_blank</v-icon>
        </v-btn>
        <v-btn icon v-show="showBindingDevicePosition===true" @click="showBindingDevicePosition=false">
          <v-icon>check_box</v-icon>
        </v-btn>
        <span>顯示綁定設備車格</span>
        <v-btn icon v-show="showUnbindingDevice===false" @click="showUnbindingDevice=true">
          <v-icon>check_box_outline_blank</v-icon>
        </v-btn>
        <v-btn icon v-show="showUnbindingDevice===true" @click="showUnbindingDevice=false">
          <v-icon>check_box</v-icon>
        </v-btn>
        <span>顯示未綁定之設備</span>
        <v-btn icon v-show="showUnbindingPosition===false" @click="showUnbindingPosition=true">
          <v-icon>check_box_outline_blank</v-icon>
        </v-btn>
        <v-btn icon v-show="showUnbindingPosition===true" @click="showUnbindingPosition=false">
          <v-icon>check_box</v-icon>
        </v-btn>
        <span>顯示未綁定設備車格</span>
        <v-spacer/>
      </v-col>
      <v-col class="col-3">
        <div class="text-end">{{ date?.format('YYYY/MM/DD HH:mm:ss') }}</div>
      </v-col>
    </v-row>
    <v-client-table :columns="['ROOT', 'MANAGER','STAFF'].includes(userRole)?columns:columnsUser"
                    :data="devices.filter(filterMethod)" :options="options">
      <template slot="h__selected">
        <v-switch inset @change="selectAll($event)"/>
      </template>
      <template slot="selected" slot-scope="{row}">
        <div>
          <v-btn icon v-show="!!row.selected===false" @click="selectDevice(row,true)">
            <v-icon>check_box_outline_blank</v-icon>
          </v-btn>
          <v-btn icon v-show="!!row.selected===true" @click="selectDevice(row,false)">
            <v-icon>check_box</v-icon>
          </v-btn>
        </div>
      </template>
      <template slot="deviceId" slot-scope="{row}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ row.deviceId ?? row.parkingPoleId }}</span>
          </template>
          <v-chip-group>
            <v-chip class="m-1" text-color="white"
                    v-for="tag in (row.deviceTags??[]).filter(item=>{
                      if (item.includes('C:')) return false
                      if (item.includes('F:')) return false
                      if (item.includes('.hide')) return false
                      return true
                    })"
                    :key="tag"
                    :color="tag.includes('.')?`${tag.split('.')[1]}`:'grey'">
              {{ tag.split('.')[0] }}
            </v-chip>
          </v-chip-group>
        </v-tooltip>
      </template>
      <template slot="deviceTags" slot-scope="{row}">
        <span v-if="!row.deviceTags"></span>
        <span class="text-danger" v-else-if="(row.deviceTags??[]).join().includes('廢品')">廢品</span>
        <span class="text-warning" v-else-if="(row.deviceTags??[]).join().includes('維修')">維修</span>
        <span v-else-if="(row.deviceTags??[]).join().includes('E:')">
          <span v-for="tag in row.deviceTags.filter(tag=>tag.includes('E:'))" v-bind:key="tag">
            <div class="text-danger" v-if="tag.includes('E:DISCONNECTED')">斷線異常</div>
            <div class="text-danger" v-else-if="tag.includes('E:LOW_POWER')">低電量異常</div>
            <div class="text-danger" v-else-if="tag.includes('E:MAGNETIC')">磁場異常</div>
            <div class="text-danger" v-else-if="tag.includes('E:INFRARED')">紅外異常</div>
            <div class="text-danger" v-else-if="tag.includes('E:BOR')">復位異常</div>
            <div class="text-danger" v-else-if="tag.includes('E:COVER')">遮蔽異常</div>
            <div class="text-warning" v-else-if="tag.includes('E:INIT')">初始化異常</div>
            <div class="text-warning" v-else-if="tag.includes('E:RSRP')">訊號異常</div>
            <div class="text-warning" v-else-if="tag.includes('E:SINR')">信噪比異常</div>
            <div class="text-warning" v-else-if="tag.includes('E:SIM')">SIM卡異常</div>
            <div class="text-success" v-else-if="tag.includes('E:LONG_PARKING')">疑似長時間停車</div>
            <div class="text-success" v-else-if="tag.includes('E:NETWORK')">網路異常</div>
            <div class="text-success" v-else>{{ tag.replace('E:', '').replace('.hide', '') }}</div>
          </span>
        </span>
        <span v-else-if="row.deviceTags?.length===0"></span>
        <span class="text-success" v-else>正常</span>
      </template>
      <span slot="isDelete" slot-scope="{row}" :class="row.isDelete ? 'red--text':'teal--text'">
        <p v-if="row.isDelete">是</p>
        <p v-else>否</p>
      </span>
      <span slot="lastStatus.cp" slot-scope="{row}" :class="(row.lastStatus?.cp ?? 0) >= 50 ? 'red--text':'teal--text'">
        <p v-show="row.lastStatus">
          <span v-if="(row.lastStatus?.cp ?? 0) < 40">無車</span>
          <span v-else-if="40 <= (row.lastStatus?.cp ?? 0) && (row.lastStatus?.cp ?? 0)  < 50">
            <span v-if="userRole==='USER'">疑似無車</span>
            <span v-else>無車</span>
          </span>
          <span v-else-if="50 <= (row.lastStatus?.cp ?? 0) && (row.lastStatus?.cp ?? 0)  < 60">
            <span v-if="userRole==='USER'">疑似有車</span>
            <span v-else>有車</span>
          </span>
          <span v-else>有車</span>
        </p>
      </span>
      <span slot="lastStatus.batteryVoltage" slot-scope="{row}"
            :class="((row.lastStatus?.batteryVoltage ?? 0) < 3300 || (row.lastStatus?.batteryVoltage ?? 0) > 10000) ? 'red--text':'teal--text'">
        <span v-if="['ROOT', 'MANAGER','STAFF'].includes(userRole)">
        <p v-show="row.lastStatus">
          {{ (row.lastStatus?.batteryVoltage ?? 0) / 1000 }}V
        </p></span>
        <span v-else>
          <p v-if="!row.lastStatus?.batteryVoltage"></p>
          <p v-else-if="(row.lastStatus?.batteryVoltage ?? 0) > 10000">電壓異常（{{
              (row.lastStatus?.batteryVoltage ?? 0) / 1000
            }}V）</p>
          <p v-else-if="(row.lastStatus?.batteryVoltage ?? 0) < 3300">低電量（{{
              (row.lastStatus?.batteryVoltage ?? 0) / 1000
            }}V）</p>
          <p v-else>電量正常（{{ (row.lastStatus?.batteryVoltage ?? 0) / 1000 }}V）</p>
        </span>
      </span>
      <span slot="lastStatus.changeTime" slot-scope="{row}">
          <p v-show="row.lastStatus">
            {{ row.lastStatus?.changeTime ? $moment(row.lastStatus.changeTime).format("YYYY/MM/DD HH:mm:ss") : "" }}
          </p>
      </span>
      <span slot="lastStatus.uploadTime" slot-scope="{row}">
        <p v-show="row.lastStatus">
          {{ row.lastStatus?.uploadTime ? $moment(row.lastStatus.uploadTime).format("YYYY/MM/DD HH:mm:ss") : "" }}
        </p>
      </span>
      <span slot="磁場靈敏度">
        <p>高</p>
      </span>
      <span slot="紅外線靈敏度">
        <p>高</p>
      </span>
      <span slot="穩態時間">
        <p>5秒</p>
      </span>
      <span slot="封包回報靈敏度">
        <p>中</p>
      </span>
      <span slot="changeStarted" slot-scope="{row}">
        <p class="primary--text" v-if="row.changeStarted === 0">完成</p>
        <p class="amber--text" v-else-if="row.changeStarted === 1">待變更</p>
        <p v-else></p>
      </span>
      <span slot="updateFirmwareStarted" slot-scope="{row}">
        <p class="primary--text" v-if="row.updateFirmwareStarted === 0">完成</p>
        <p class="purple--text" v-else-if="row.updateFirmwareStarted === 1">待升級</p>
        <p class="deep-orange--text" v-else-if="row.updateFirmwareStarted === 2">升級中</p>
        <p v-else></p>
      </span>
      <template slot="action" slot-scope="{row}">
        <v-btn-toggle>
          <v-menu bottom left offset-y v-if="['ROOT', 'MANAGER','STAFF','MEMBER'].includes(userRole)">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on">操作</v-btn>
            </template>
            <v-card>
              <v-row>
                <v-col>
                  <v-list dense>
                    <v-list-item
                        v-if="vehicleDetectorEnable || parkingPoleLuciferEnable"
                        v-show="(row.deviceId || row.parkingPoleId) && (firstPermissions & 0b000000000000000000000000001000) === 0b000000000000000000000000001000"
                        @click="selectDeviceStatus=row">
                      <v-list-item-content>
                        <v-list-item-title class="text-primary">解析數據</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="vehicleDetectorEnable || parkingPoleLuciferEnable"
                        v-show="(row.deviceId || row.parkingPoleId) && (firstPermissions & 0b000000000000000000000000000100) === 0b000000000000000000000000000100"
                        @click="selectDeviceChangeLog=row">
                      <v-list-item-content>
                        <v-list-item-title class="teal--text">車位變換紀錄</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="vehicleDetectorEnable || parkingPoleLuciferEnable"
                        v-show="(row.deviceId || row.parkingPoleId) && (firstPermissions & 0b000000000000000000000000010000) === 0b000000000000000000000000010000"
                        @click="selectDeviceUnmoralLog=row">
                      <v-list-item-content>
                        <v-list-item-title class="red--text">異常紀錄</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col v-if="['ROOT', 'MANAGER','STAFF'].includes(userRole)">
                  <v-list dense>
                    <v-list-item
                        v-if="vehicleDetectorEnable"
                        v-show="row.deviceId && (firstPermissions & 0b000000000000000000000010000000) === 0b000000000000000000000010000000"
                        @click="selectDeviceSetting=[row.deviceId]">
                      <v-list-item-content>
                        <v-list-item-title class="text-primary">設定</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="parkingPoleLuciferEnable"
                        v-show="row.parkingPoleId && (firstPermissions & 0b000000000000000000000010000000) === 0b000000000000000000000010000000"
                        @click="selectPoleSetting=[row.parkingPoleId]">
                      <v-list-item-content>
                        <v-list-item-title class="text-primary">設定</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="vehicleDetectorEnable"
                        v-show="row.deviceId && (firstPermissions & 0b000000000000000000000001000000) === 0b000000000000000000000001000000"
                        @click="selectDeviceNet=row">
                      <v-list-item-content>
                        <v-list-item-title class="teal--text">網路數據</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="vehicleDetectorEnable"
                        v-show="row.deviceId && (firstPermissions & 0b000000000000000000000100000000) === 0b000000000000000000000100000000"
                        @click="selectDeviceUpgrade=[{deviceId:row.deviceId, firmwareVersion:row.firmwareVersion, bid:row.bid ?? 17}]">
                      <v-list-item-content>
                        <v-list-item-title class="cyan--text">升級韌體</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="vehicleDetectorEnable || parkingPoleLuciferEnable"
                        v-show="(row.deviceId || row.parkingPoleId) && (firstPermissions & 0b000000000000000000001000000000) === 0b000000000000000000001000000000"
                        @click="selectDeviceTag=row">
                      <v-list-item-content>
                        <v-list-item-title class="purple--text">標籤管理</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col v-if="['ROOT', 'MANAGER','STAFF'].includes(userRole)">
                  <v-list dense>
                    <v-list-item
                        v-if="positionEnable"
                        v-show="row.id && (firstPermissions & 0b000000000000000010000000000000) === 0b000000000000000010000000000000"
                        @click="selectParkingPositionBingDevice=row">
                      <v-list-item-content>
                        <v-list-item-title class="text-primary">綁定設備</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="positionEnable"
                        v-show="(!organizationEnable || selectDistrictAppId!=='') && (firstPermissions & 0b000000000000000010000000000000) === 0b000000000000000010000000000000"
                        @click="selectParkingPositionUpdate=row">
                      <v-list-item-content>
                        <v-list-item-title class="teal--text" v-if="!row.id">新增停車格</v-list-item-title>
                        <v-list-item-title class="teal--text" v-else>更新停車格</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-menu bottom offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                            v-if="organizationEnable && (positionEnable ||parkingPoleLuciferEnable)"
                            v-show="(row.deviceId || row.parkingPoleId) && (firstPermissions & 0b000000000000000010000000000000) === 0b000000000000000010000000000000"
                            v-bind="attrs" v-on="on">
                          <v-list-item-content>
                            <v-list-item-title class="orange--text">編輯組織、場域</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-list dense>
                        <template v-for="organization in organizations">
                        <span :key="organization.id">
                          <v-menu bottom offset-x left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-content>
                                  <v-list-item-title>{{ organization.name }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                            <v-list dense>
                              <v-list-item @click="handleChangeOrganization(row, organization)">
                                <v-list-item-content>
                                  <v-list-item-title>無場域</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <template v-for="district in organization.districts">
                                <span :key="district.id">
                                  <v-list-item @click="handleDeviceChangeField(row, district)">
                                    <v-list-item-content>
                                      <v-list-item-title>{{ district.name }}</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="m-0"/>
                                </span>
                              </template>
                            </v-list>
                          </v-menu>
                          <v-divider class="m-0"/>
                        </span>
                        </template>
                      </v-list>
                    </v-menu>
                    <v-list-item
                        v-if="vehicleDetectorEnable"
                        v-show="row.deviceId && (firstPermissions & 0b000000000000000000000010000000) === 0b000000000000000000000010000000"
                        @click="setDefaultSetting(row)">
                      <v-list-item-content>
                        <v-list-item-title class="purple--text">還原出廠設定</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>
          <template v-else-if="vehicleDetectorEnable || parkingPoleLuciferEnable">
            <v-btn color="teal"
                   v-show="(row.deviceId || row.parkingPoleId) && (firstPermissions & 0b000000000000000000000000001000) === 0b000000000000000000000000001000"
                   @click="selectDeviceStatus=row">解析數據
            </v-btn>
            <v-btn color="primary"
                   v-show="(row.deviceId || row.parkingPoleId) && (firstPermissions & 0b000000000000000000000000000100) === 0b000000000000000000000000000100"
                   @click="selectDeviceChangeLog=row">車位變換紀錄
            </v-btn>
          </template>
          <v-btn color="orange" @click="reloadDevice(row)">
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-client-table>
    <v-dialog content-class="dialog-content-big"
              scrollable
              :value="selectDeviceStatus"
              @click:outside="selectDeviceStatus = undefined">
      <v-card v-if="!!selectDeviceStatus">
        <v-card-title>
          <div class="me-1">{{ selectDeviceStatus.address }}</div>
          <div class="me-3">{{ selectDeviceStatus.name }}</div>
          <div class="me-3" v-show="selectDeviceStatus.deviceId??selectDeviceStatus.parkingPoleId">
            ({{ selectDeviceStatus.deviceId ?? selectDeviceStatus.parkingPoleId }})
          </div>
          設備上傳數據
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceStatus = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceStatusFrom :device-id="selectDeviceStatus.deviceId"
                            v-if="selectDeviceStatus.deviceId"/>
          <ParkingPoleStatusFrom :device-id="selectDeviceStatus.parkingPoleId"
                                 v-else-if="selectDeviceStatus.parkingPoleId"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-big"
              scrollable
              :value="selectDeviceChangeLog"
              @click:outside="selectDeviceChangeLog = undefined">
      <v-card v-if="!!selectDeviceChangeLog">
        <v-card-title>
          <div class="me-1">{{ selectDeviceChangeLog.address }}</div>
          <div class="me-3">{{ selectDeviceChangeLog.name }}</div>
          <div class="me-3" v-show="selectDeviceChangeLog.deviceId??selectDeviceChangeLog.parkingPoleId">
            ({{ selectDeviceChangeLog.deviceId ?? selectDeviceChangeLog.parkingPoleId }})
          </div>
          車位變換紀錄
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceChangeLog = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <ChangeLogFrom :device-id="selectDeviceChangeLog.deviceId"
                         v-if="selectDeviceChangeLog.deviceId"/>
          <ParkingPoleChangeLogFrom :device-id="selectDeviceChangeLog.parkingPoleId"
                                    v-else-if="selectDeviceChangeLog.parkingPoleId"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog content-class="dialog-content-big"
              scrollable
              :value="selectDeviceUnmoralLog"
              @click:outside="selectDeviceUnmoralLog = undefined">
      <v-card v-if="!!selectDeviceUnmoralLog">
        <v-card-title>
          <div class="me-1">{{ selectDeviceUnmoralLog.address }}</div>
          <div class="me-1">{{ selectDeviceUnmoralLog.name }}</div>
          <div class="me-3" v-show="selectDeviceUnmoralLog.deviceId??selectDeviceUnmoralLog.parkingPoleId">
            ({{ selectDeviceUnmoralLog.deviceId ?? selectDeviceUnmoralLog.parkingPoleId }})
          </div>
          異常紀錄
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceUnmoralLog = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <UnmoralLogFrom :device-id="selectDeviceUnmoralLog.deviceId"
                          v-if="selectDeviceUnmoralLog.deviceId"/>
          <ParkingPoleUnmoralLogFrom :device-id="selectDeviceUnmoralLog.parkingPoleId"
                                     v-else-if="selectDeviceUnmoralLog.parkingPoleId"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-big"
              scrollable
              :value="selectDeviceSetting || selectPoleSetting"
              @click:outside="selectDeviceSetting = undefined;selectPoleSetting= undefined">
      <v-card v-if="selectDeviceSetting || selectPoleSetting">
        <v-card-title>
          <p>設定</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceSetting = undefined;selectPoleSetting= undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <SettingFrom :device-id="selectDeviceSetting" v-if="selectDeviceSetting"
                       @close="selectDeviceSetting = undefined"/>
          <ParkingPoleSettingFrom :device-id="selectPoleSetting" v-else-if="selectPoleSetting"
                                  @close="selectPoleSetting= undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content"
              scrollable
              :value="selectDeviceUpgrade"
              @click:outside="selectDeviceUpgrade = undefined">
      <v-card v-if="!!selectDeviceUpgrade">
        <v-card-title>
          <p>升級</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceUpgrade = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceUpgradeFrom :devices="selectDeviceUpgrade"
                             @close="selectDeviceUpgrade = undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-small"
              scrollable
              :value="selectDeviceTag"
              @click:outside="selectDeviceTag = undefined">
      <v-card v-if="!!selectDeviceTag">
        <v-card-title>
          <p>標籤管理</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceTag = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceTagForm :device-id="selectDeviceTag.deviceId"
                         :tags="selectDeviceTag.deviceTags"
                         v-if="selectDeviceTag.deviceId"
                         @close="closeDeviceTag"/>
          <ParkingPoleTagForm :device-id="selectDeviceTag.parkingPoleId"
                              :tags="selectDeviceTag.deviceTags"
                              v-else-if="selectDeviceTag.parkingPoleId"
                              @close="closeParkingPoleTag"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content"
              scrollable
              :value="selectDeviceAddTag"
              @click:outside="selectDeviceAddTag = undefined">
      <v-card v-if="!!selectDeviceAddTag">
        <v-card-title>
          <p>批量新增標籤</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceAddTag = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceTagArrayAddTagForm :device-id="selectDeviceAddTag"
                                    @close="selectDeviceAddTag = undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content"
              scrollable
              :value="selectDeviceRemoveTag"
              @click:outside="selectDeviceRemoveTag = undefined">
      <v-card v-if="!!selectDeviceRemoveTag">
        <v-card-title>
          <p>批量移除標籤</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceRemoveTag = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceTagArrayRemoveTagForm :device-id="selectDeviceRemoveTag"
                                       @close="selectDeviceRemoveTag = undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-small"
              scrollable
              :value="selectParkingPositionBingDevice"
              @click:outside="selectParkingPositionBingDevice = undefined">
      <v-card v-if="!!selectParkingPositionBingDevice">
        <v-card-title>
          <p>綁定設備</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectParkingPositionBingDevice = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <BingDeviceFrom :parking-position="selectParkingPositionBingDevice"
                          @close="selectParkingPositionBingDevice = undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-small"
              scrollable
              :value="selectParkingPositionUpdate"
              @click:outside="selectParkingPositionUpdate = undefined">
      <v-card v-if="!!selectParkingPositionUpdate">
        <v-card-title>
          <p v-if="!selectParkingPositionUpdate.id">新增停車格</p>
          <p v-else>更新停車格</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectParkingPositionUpdate = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="$dialog-w">
          <PositionCreateOrUpdateFrom :parking-position="selectParkingPositionUpdate"
                                      @close="selectParkingPositionUpdate = undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-small"
              scrollable
              :value="selectDeviceNet"
              @click:outside="selectDeviceNet = undefined">
      <v-card v-if="!!selectDeviceNet">
        <v-card-title>
          <p>網路數據</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceNet = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceNetFrom :device-id="selectDeviceNet.deviceId"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapState, mapActions} from "pinia";
import {mainStore, toast, useLoadingStore} from "@/Store";
import DeviceStatusFrom from "@/components/DeviceStatusFrom";
import ChangeLogFrom from "@/components/ChangeLogFrom";
import UnmoralLogFrom from "@/components/UnmoralLogFrom";
import SettingFrom from "@/components/SettingFrom";
import DeviceUpgradeFrom from "@/components/DeviceUpgradeFrom";
import BingDeviceFrom from "@/components/BingDeviceFrom";
import PositionCreateOrUpdateFrom from "@/components/PositionCreateOrUpdateFrom";
import DeviceTagForm from "@/components/DeviceTagForm";
import DeviceTagArrayAddTagForm from "@/components/DeviceTagArrayAddTagForm";
import DeviceTagArrayRemoveTagForm from "@/components/DeviceTagArrayRemoveTagForm";
import DeviceNetFrom from "@/components/DeviceNetFrom";
import {filterDeviceWithKeywordAndExcludeKeyword} from "@/plugins/methods";
import {getDevice, getPosition, getServerTime, setDefaultDeviceSetting} from "@/Api";
import ParkingPoleStatusFrom from "@/components/parking-pole/ParkingPoleStatusFrom";
import ParkingPoleChangeLogFrom from "@/components/parking-pole/ParkingPoleChangeLogFrom";
import ParkingPoleUnmoralLogFrom from "@/components/parking-pole/ParkingPoleUnmoralLogFrom";
import ParkingPoleSettingFrom from "@/components/parking-pole/ParkingPoleSettingFrom";
import ParkingPoleTagForm from "@/components/parking-pole/ParkingPoleTagForm.vue";
import {deviceBindField, deviceBindOrganization} from "@/Api-parking-pole";

export default {
  name: "PositionSearch",
  components: {
    ParkingPoleTagForm,
    ParkingPoleSettingFrom,
    ParkingPoleUnmoralLogFrom,
    ParkingPoleChangeLogFrom,
    ParkingPoleStatusFrom,
    DeviceTagArrayRemoveTagForm,
    DeviceTagArrayAddTagForm,
    DeviceTagForm,
    PositionCreateOrUpdateFrom,
    BingDeviceFrom,
    DeviceUpgradeFrom,
    DeviceStatusFrom,
    ChangeLogFrom,
    UnmoralLogFrom,
    SettingFrom,
    DeviceNetFrom
  },
  computed: {
    ...mapState(mainStore, ['userRole', 'firstPermissions']),
    ...mapState(mainStore, ['oauthEnable', 'organizationEnable', 'positionEnable', 'vehicleDetectorEnable', 'parkingPoleLuciferEnable']),
    ...mapState(mainStore, ['organizations', 'districts', 'devices', 'selectOrganizationAppId', 'selectDistrictAppId']),
    ...mapState(mainStore, ['loading']),
  },
  data() {
    return {
      expansion: [],
      keyword: '',
      excludeKeyword: '',
      columnsUser: ['selected', 'deviceId', 'address', 'name', 'lastStatus.cp', 'lastStatus.batteryVoltage', 'lastStatus.rsrp', '磁場靈敏度', '紅外線靈敏度', '穩態時間', '封包回報靈敏度', 'firmwareVersion', 'lastStatus.changeTime', 'lastStatus.uploadTime', 'action'],
      columns: ['selected', 'deviceId', 'address', 'name', 'deviceTags', 'isDelete', 'lastStatus.cp', 'lastStatus.batteryVoltage', 'firmwareVersion', 'changeStarted', 'updateFirmwareStarted', 'lastStatus.changeTime', 'lastStatus.uploadTime', 'action'],
      options: {
        headings: {
          selected: '',
          deviceId: '設備ID',
          address: '地址',
          name: '車格編號',
          deviceTags: '狀態',
          isDelete: '刪除',
          'lastStatus.rsrp': 'RSRP',
          'lastStatus.cp': '是否停車',
          'lastStatus.batteryVoltage': '電量',
          'lastStatus.changeTime': '設備上傳時間',
          'lastStatus.uploadTime': '資料接收時間',
          firmwareVersion: '韌體版本',
          changeStarted: '設備設定狀態',
          updateFirmwareStarted: '設備升級狀態',
          action: '操作'
        },
        orderBy: {ascending: false, column: 'deviceId'},
        sortable: ['deviceId', 'address', 'name', 'deviceTags', 'isDelete', 'lastStatus.cp', 'lastStatus.batteryVoltage', 'lastStatus.rsrp', 'lastStatus.changeTime', 'lastStatus.uploadTime', 'changeStarted', 'updateFirmwareStarted'],
        filterable: false,
      },
      date: undefined,
      showBindingDevicePosition: true,
      showUnbindingDevice: true,
      showUnbindingPosition: true,
      selectedAll: false,
      selectDeviceStatus: undefined,
      selectDeviceChangeLog: undefined,
      selectDeviceUnmoralLog: undefined,
      selectDeviceSetting: undefined,
      selectPoleSetting: undefined,
      selectDeviceUpgrade: undefined,
      selectDeviceTag: undefined,
      selectDeviceAddTag: undefined,
      selectDeviceRemoveTag: undefined,
      selectParkingPositionBingDevice: undefined,
      selectParkingPositionUpdate: undefined,
      selectDeviceNet: undefined,
    }
  },
  methods: {
    ...mapActions(toast, ['success', 'error']),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    ...mapActions(mainStore, ['reloadDevices', 'onDeviceChangeOrganization', 'onDeviceChangeField', 'reloadView', 'updateDeviceView', 'updateParkingPoleView']),
    filterMethod(device) {
      if (this.showBindingDevicePosition && device.id && (device.deviceId || device.parkingPoleId)) {
        return filterDeviceWithKeywordAndExcludeKeyword(device, this.keyword, this.excludeKeyword, this.userRole)
      }
      if (this.showUnbindingDevice && !device.id && (device.deviceId || device.parkingPoleId)) {
        return filterDeviceWithKeywordAndExcludeKeyword(device, this.keyword, this.excludeKeyword, this.userRole)
      }
      if (this.showUnbindingPosition && device.id && !(device.deviceId || device.parkingPoleId)) {
        return filterDeviceWithKeywordAndExcludeKeyword(device, this.keyword, this.excludeKeyword, this.userRole)
      }
      return false
    },
    selectAll(value) {
      this.devices.filter(device => this.filterMethod(device, this.keyword)).forEach(item => {
        item.selected = value
      })
      this.reloadView()
    },
    selectDevice(device, value) {
      this.devices.filter(item => (item.deviceId ?? item.id) === (device.deviceId ?? device.id)).forEach(item => {
        item.selected = value
      })
      this.reloadView()
    },
    async selectedDeviceChangeOrganization(organization) {
      let list = this.devices.filter(device => device.selected && device.deviceId)
      await this.onDeviceChangeOrganization(list, organization)
    },
    async selectedDeviceChangeDistrict(district) {
      let list = this.devices.filter(device => device.selected && device.deviceId)
      await this.handleDeviceChangeField(list, district)
    },
    tagging() {
      if (this.expansion.length === 0) {
        this.expansion = [0]
      } else {
        this.expansion = []
      }
    },
    async reloadDevice(device) {
      if (device.id) {
        let position = await getPosition(device.id)
        await this.updateDeviceView(device, position)
      } else if (device.deviceId) {
        let position = await getDevice(device.deviceId)
        await this.updateDeviceView(device, position)
      } else if (device.parkingPoleId) {
        await this.reloadDevices()
      } else {
        this.error('無法更新，沒有指定ID欄位')
      }
    },
    async runClock() {
      this.date = this.$moment(this.date.add(1, 's'))
      setTimeout(this.runClock, 1000)
    },
    async closeDeviceTag(deviceTags) {
      await this.updateDeviceView(this.selectDeviceTag, {...this.selectDeviceTag, deviceTags})
    },
    async closeParkingPoleTag() {
      await this.reloadDevices()
    },
    async handleChangeOrganization(row, organization) {
      if (row.deviceId) {
        await this.onDeviceChangeOrganization(row, organization)
      } else if (row.parkingPoleId) {
        this.showLoading()
        let response = await deviceBindOrganization(row.parkingPoleId, organization.appId)
        this.dismissLoading()
        if (response.ok) {
          this.success('更新成功')
        } else {
          this.error(response.message)
        }
        await this.reloadDevices()
      }
    },
    async handleDeviceChangeField(row, district) {
      if (row.deviceId) {
        await this.onDeviceChangeField(row, district)
      } else if (row.parkingPoleId) {
        this.showLoading()
        let response = await deviceBindField(row.parkingPoleId, district.appId)
        this.dismissLoading()
        if (response.ok) {
          this.success('更新成功')
          await this.reloadDevices()
        } else {
          this.error(response.message)
        }
      }
    },

    async setDefaultSetting(device) {
      this.showLoading()
      let response = await setDefaultDeviceSetting(device.deviceId)
      this.dismissLoading()
      if (response.ok) {
        this.success('更新成功')
        this.reloadDevice(device)
      } else {
        this.error(response.message)
      }
    }
  },
  mounted() {
    let temp = this
    getServerTime().then(value => {
      temp.date = temp.$moment(value)
      temp.runClock().then()
    })
  }
}
</script>

<style scoped>

</style>